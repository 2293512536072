import { SocialIcon } from "react-social-icons";
import s from './Footer.module.scss';
import clsx from "clsx";

export default function Footer() {
  return (
    <footer className={clsx(s.footer, "text-white py-10 text-sm")}>
      <div className="mt-2 flex flex-col items-center md:flex-row md:justify-center">
        <p className="order-2 mt-8 md:order-1 md:mx-1 md:mt-0">
          {" "}
          &copy; ConFuzzled UK Ltd, {new Date().getFullYear()}.{" "}
        </p>
        <div className="order-1 md:order-2 md:mx-1">
          Made with <div className="made-paw inline-block px-2"></div> by{" "}
          <a href="https://twitter.com/AlwaHusky" target="_blank" rel="noreferrer">
            {" "}
            Alwa
          </a>
        </div>
      </div>
      <div className="mx-auto mt-2 flex flex-row items-center justify-center">
        <SocialIcon className="mx-0.5" style={{ height: 25, width: 25 }} url="https://twitter.com/cfconvention" />
        <SocialIcon className="mx-0.5" style={{ height: 25, width: 25 }} url="https://t.me/ConFuzzled" />
      </div>
    </footer>
  );
}
