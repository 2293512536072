import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/shadcn/components/ui/form.tsx";
import { Input } from "@/shadcn/components/ui/input.tsx";
import { Textarea } from "@/shadcn/components/ui/textarea.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/shadcn/components/ui/popover.tsx";
import { Button } from "@/shadcn/components/ui/button.tsx";
import { cn } from "@/shadcn/utils.ts";
import dayjs from "dayjs";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/shadcn/components/ui/calendar.tsx";
import { Switch } from "@/shadcn/components/ui/switch.tsx";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "@/shadcn/components/ui/use-toast.ts";
import { useAuth } from "react-oidc-context";
import { Modal } from "@/components/Modal.tsx";

type Props = {
  open: boolean;
  onClose: () => void;
  editing?: boolean;
  onCreated?: (item: WeaponItem & {id: number}) => void,
  onUpdated?: (item: WeaponItem & {id: number}) => void,
  originalData?: WeaponItem & {id: number};
}

const schema = z.object({
  name: z.string().min(3),
  date: z.date(),
  description: z.string().min(10),
  owner: z.string(),
  picture: z.string(),
  allowed: z.boolean(),
  restricted: z.boolean(),
  loggedBy: z.string().min(1),
  notes: z.string().optional()
});

export type WeaponItem = z.infer<typeof schema>;


export function WeaponForm({ open, onClose, editing, onUpdated, onCreated, originalData: original }: Props) {
  const auth = useAuth();
  const { toast } = useToast();

  const createOrEdit = async (values: WeaponItem) => {
    const isEditing = !!originalId;

    // Convert the image to base64
    if (file) {
      const imageBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        }
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      values.picture = imageBase64 as string;
    } else if (isEditing && original?.picture) {
      values.picture = original.picture;
    }

    fetch(`${import.meta.env.VITE_APP_API_URL}/v1/staff/items/weapons${isEditing ? "/" + originalId : ""}`, {
      method: isEditing ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${auth.user?.access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        if (data?.error) {
          throw new Error(data.error);
        }

        if (onCreated && !isEditing) {
          onCreated(data);
        }

        if (onUpdated && isEditing) {
          onUpdated(data);
        }

        onDone();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        toast({
          title: "Error",
          description: err.message,
          variant: "destructive"
        })
      });
    onDone();
  };

  const [file, setFile] = useState<File | null>(null);
  const [originalId, setOriginalId] = useState<number | null>(null);

  useEffect(() => {
    if (!open) {
      form.reset();
      setOriginalId(null);
      setFile(null);
      return;
    }

    if (original) {
      Object.keys(original).forEach((key) => {
        if (key === "picture") {
          return;
        }

        if (key === "date") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          form.setValue(key as any, dayjs(original[key as keyof WeaponItem] as string).toDate());
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        form.setValue(key as any, original[key as keyof WeaponItem]);
      });

      setOriginalId(original.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original, open])

  const form = useForm<WeaponItem>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      date: new Date(),
      description: "",
      owner: "",
      picture: "",
      allowed: false,
      restricted: false,
      loggedBy: "",
      notes: ""
    }
  });

  const previewImage = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return null;
  }, [file]);

  const onDone = () => {
    form.reset();
    setOriginalId(null);
    setFile(null);

    onClose();
  }

  return <Modal isOpen={open} onClose={() => onDone()} title={`${editing ? "Edit" : "Add"} Weapon`}>
    <Form {...form}>
      <form className="space-y-4" onSubmit={form.handleSubmit(createOrEdit, (e) => console.log(e))}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Weapon Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Tell us about the weapon"
                  className="resize-none"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Notes</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Notes"
                  className="resize-none"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Date Found</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-[240px] pl-3 text-left font-normal",
                        !field.value && "text-muted-foreground"
                      )}
                    >
                      {field.value ? (
                        dayjs(field.value).format("DD/MM/YYYY")
                      ) : (
                        <span>Pick a date</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="picture"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Picture of Item</FormLabel>
              <FormControl>
                <Input id="picture" type="file" {...field} onChange={(e) => {
                  if (e.target.files) {
                    setFile(e.target.files[0]);
                  }

                  field.onChange(e);
                }} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {previewImage}

        {previewImage && <div>
          <p>Preview</p>
          <img src={previewImage} alt="Preview" width={200} />
        </div>}

        {!previewImage && editing && original?.picture && <div>
          <p>Preview</p>
          <img src={original?.picture} alt="Preview" width={200} />
        </div>}


        <FormField
          control={form.control}
          name="allowed"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
              <div className="space-y-0.5">
                <FormLabel>
                  Allowed
                </FormLabel>
                <FormDescription>
                  Is the item allowed to be carried around the conversion
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="restricted"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
              <div className="space-y-0.5">
                <FormLabel>
                  Restricted
                </FormLabel>
                <FormDescription>
                  This item can only be handled during the photoshoot
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="owner"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Owner</FormLabel>
              <FormControl>
                <Input placeholder="Jake" {...field} />
              </FormControl>
              <FormDescription>
                The name of the owner
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="loggedBy"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Logged by</FormLabel>
              <FormControl>
                <Input placeholder="Jake" {...field} />
              </FormControl>
              <FormDescription>
                The name of the person who logged the item
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">{editing ? "Update" : "Create"}</Button>
      </form>
    </Form>
  </Modal>;
}
