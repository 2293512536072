import { useEffect, useMemo, useState } from "react";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { DataItem, ItemForm } from "@/components/Forms/ItemForm.tsx";
import { Input } from "@/shadcn/components/ui/input";
import { Button } from "@/shadcn/components/ui/button";
import { FilterIcon, MapPinIcon, PencilIcon, PlusIcon, ShoppingBagIcon, StarIcon, TrashIcon } from "lucide-react";
import NotFoundImage from "@/assets/cfz-notfound.png";
import { Switch } from "@/shadcn/components/ui/switch";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/shadcn/components/ui/tooltip";
import { Popover, PopoverContent, PopoverTrigger } from "@/shadcn/components/ui/popover";

export const LostAndFound = withAuthenticationRequired(
  () => {
    const auth = useAuth();

    const props = useMemo(() => {
      const local = localStorage.getItem("props");
      if (!local) {
        return { hideCollected: true, hideDiscarded: true, hideFound: true };
      }
      return JSON.parse(local);
    }, [])

    const [items, setItems] = useState<DataItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [openOrEdit, setOpenOrEdit] = useState(false);
    const [editForm, setEditForm] = useState<DataItem | null>(null);

    const [hideCollected, setHideCollected] = useState(props.hideCollected)
    const [hideDiscarded, setHideDiscarded] = useState(props.hideDiscarded);
    const [hideFound, setHideFound] = useState(props.hideFound);

    const [search, setSearch] = useState("");

    useEffect(() => {
      setError(null);
      setLoading(true);

      // ?search
      fetch(`${import.meta.env.VITE_APP_API_URL}/v1/staff/items/lost`, {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`,
          "Content-Type": "application/json",
        }
      })
        .then((res) => res.json())
        .then((data) => {
          if (!Array.isArray(data)) {
            throw new Error("Invalid response");
          }

          setItems(data);
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    function shouldShow(item: DataItem) {
      if (hideCollected && item.collected) {
        return false;
      }

      if (hideDiscarded && item.discarded) {
        return false;
      }

      if (hideFound && item.lost) {
        return false;
      }

      return true;
    }

    const shownItems = useMemo(() => {
      if (!search) {
        return items.filter((item) => shouldShow(item));
      }

      const searchLower = search.toLowerCase();
      return items.filter((item) =>
        shouldShow(item) &&
        (
          item.id.toString().includes(searchLower) ||
          item.name.toLowerCase().includes(searchLower) ||
          item.description.toLowerCase().includes(searchLower) ||
          item.location.toLowerCase().includes(searchLower) ||
          item.owner.toLowerCase().includes(searchLower)
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, hideCollected, search, hideDiscarded, hideFound]);

    const sortedShownItems = useMemo(() => {
      return shownItems.sort((a, b) => {
        return b.id - a.id
      });
    }, [shownItems]);

    const collectedItems = useMemo(() => items.filter((item) => item.collected).length, [items]);
    const discardedItems = useMemo(() => items.filter((item) => item.discarded).length, [items]);
    const foundItems = useMemo(() => items.filter((item) => item.lost).length, [items]);

    useEffect(() => {
      localStorage.setItem("props", JSON.stringify({ hideCollected, hideDiscarded, hideFound }));
    }, [hideCollected, hideDiscarded, hideFound]);

    return (
      <>
        <div className="flex flex-col">
          <h1 className="font-bold text-lg mb-4">Lost & Found</h1>
          <div className="flex justify-between gap-4 mb-8">
            <div className="main flex gap-2">
              <Input type="search" placeholder="Search..." className="text-black" value={search} onChange={(e) => setSearch(e.target.value)} />

              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline">
                    <FilterIcon size={20} />
                  </Button>
                </PopoverTrigger>
                <PopoverContent side="bottom" align="end">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center space-x-4">
                      <Switch id="collected" checked={hideCollected} onCheckedChange={e => setHideCollected(e.valueOf() as boolean)} />

                      <label
                        htmlFor="collected"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        Hide Collected ({collectedItems})
                      </label>
                    </div>

                    <div className="flex items-center space-x-4">
                      <Switch id="discared" checked={hideDiscarded} onCheckedChange={e => setHideDiscarded(e.valueOf() as boolean)} />

                      <label
                        htmlFor="discared"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        Hide Discard ({discardedItems})
                      </label>
                    </div>

                    <div className="flex items-center space-x-4">
                      <Switch id="terms" checked={hideFound} onCheckedChange={e => setHideFound(e.valueOf() as boolean)} />

                      <label
                        htmlFor="terms"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        Hide Found ({foundItems})
                      </label>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </div>

            <Button onClick={() => setOpenOrEdit(true)} >
              <PlusIcon size={20} className="mr-0 sm:mr-2" />
              <span className="hidden sm:block">Add Item</span>
            </Button>
          </div>

          {(!loading && !error) && <div className="items grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 items-start">
            {sortedShownItems.map((item, index) => {
              const { picture, ...rest } = item;
              return <div key={index} className="item rounded mb-4 shadow">
                <div className="relative">
                  <TooltipProvider>
                    {picture && <img className="rounded-t aspect-square" src={picture} />}
                    {!picture && <img className="rounded-t aspect-square" src={NotFoundImage} />}
                    <Button size="sm" className="absolute top-2 right-2" variant="outline" onClick={() => {
                        setEditForm(item);
                        setOpenOrEdit(true);
                    }}><PencilIcon size={16} /></Button>

                    <div className="buttons gap-2 flex absolute bottom-4 left-4">
                      {rest.discarded && <Tooltip>
                        <TooltipTrigger><TrashIcon size="20" className="text-white" /></TooltipTrigger>
                        <TooltipContent>
                          <p>Discarded</p>
                        </TooltipContent>
                      </Tooltip>}
                      {rest.collected && <Tooltip>
                        <TooltipTrigger><ShoppingBagIcon size="20" className="text-white" /></TooltipTrigger>
                        <TooltipContent>
                          <p>Collected</p>
                          </TooltipContent>
                      </Tooltip>}
                      {/* Jake, fix me */}
                      {rest.lost && <Tooltip>
                        <TooltipTrigger><StarIcon size="20" className="text-white" /></TooltipTrigger>
                        <TooltipContent>
                          <p>Found</p>
                        </TooltipContent>
                      </Tooltip>}
                    </div>
                  </TooltipProvider>
                </div>

                <div className="p-4">
                  <div className="font-bold mb-1">{rest.name}</div>
                  <div className="flex items-center gap-2"><MapPinIcon size={16} /> {rest.location}</div>
                </div>
              </div>
            })}

            {(shownItems.length === 0 && search !== "") && <div>No items found for <code>{search}</code></div>}
            {(shownItems.length === 0 && search === "") && <div>No items logged</div>}
          </div>}

          {error && <div>{error}</div>}
          {loading && <div>Loading...</div>}

          <ItemForm
            isOpen={openOrEdit}
            original={editForm ?? undefined}
            onClose={() => {
              setOpenOrEdit(false);
              setEditForm(null);
            }}
            onCreated={(item) => {
              setItems((prev) => [...prev, item]);
            }}
            onUpdated={(item) => {
              setItems((prev) => {
                const index = prev.findIndex((i) => i.id === item.id);
                if (index === -1) {
                  return prev;
                }

                const copy = [...prev];
                copy[index] = item;
                return copy;
              });
            }}
          />
        </div>
      </>
    );
  },
  { signinRedirectArgs: { prompt: "login" } },
);
