import { AuthContextProps, useAuth } from "react-oidc-context";
import { Button } from "@/shadcn/components/ui/button.tsx";
import { Link } from "react-router-dom";
import {  LogOutIcon, Menu, WrenchIcon } from "lucide-react";
import s from "./Header.module.scss";
import { clsx } from "clsx";
import Container from "@/components/Container.tsx";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import ConFuzzledLogo from "@/assets/cfzlogo_horizontal_white.svg?react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import ConFuzzledPurpleLogo from "@/assets/cfzlogo_horizontal_purple.svg?react";

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/shadcn/components/ui/navigation-menu";
import { ReactNode } from "react";
import { cn } from "@/shadcn/utils";
import { authUserHasRoles } from "@/utils.ts";
import { Constants } from "@/constants.ts";
import {
  Sheet, SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "@/shadcn/components/ui/sheet";

function createOption(to: string, title: string, desc = "", predicate?: (auth: AuthContextProps) => boolean) {
  return { to, title, desc, predicate };
}

const navOptions = [
  {
    name: "ConOps",
    icon: WrenchIcon,
    options: [
      createOption("/conops/lost-found", "Lost & Found", "Manage lost and found items"),
      createOption("/conops/weapons", "Weapons/Props", "Manage weapons and props"),
    ],
    predicate: (auth: AuthContextProps) => authUserHasRoles(auth, [Constants.roles.conops])
  }
]

export function Header() {
  const auth = useAuth();

  return (
    <div className={clsx(s.header)}>
      <Container>
        <header className="flex items-center justify-between py-4">
          <div className="main flex gap-8 ">
            <div className="logo flex items-center gap-4">
              <Link to="/dashboard">
                <ConFuzzledLogo style={{ width: 120 }} />
              </Link>
              <p className="font-black text-white">Internal Staff Hub</p>
            </div>

            {(!auth.isLoading && auth.isAuthenticated) && <NavigationMenu className="hidden md:block">
              <NavigationMenuList>
                {navOptions
                  .filter((navOption) => navOption.predicate(auth))
                  .map((navOption, navIndex) => {
                    const IconComp = navOption.icon;
                    return <NavigationMenuItem key={navIndex}>
                      <NavigationMenuTrigger>
                        <IconComp size={18} className="mr-2" />
                        {navOption.name}
                      </NavigationMenuTrigger>
                      <NavigationMenuContent>
                        <ul className="md:w-[150px] lg:w-[200px]">
                          {navOption.options
                            .filter((option) => !option.predicate || option.predicate(auth))
                            .map((option, optionIndex) => <ListItem to={option.to} title={option.title} key={optionIndex}>
                                {option.desc}
                              </ListItem>)}
                        </ul>
                      </NavigationMenuContent>
                    </NavigationMenuItem>;
                  })}
              </NavigationMenuList>
            </NavigationMenu>}
          </div>
          <div className="flex items-center gap-4 md:hidden">
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="outline">
                  <Menu />
                </Button>
              </SheetTrigger>
              <SheetContent side="left">
                <div className="mx-auto w-full max-w-sm">
                  <SheetHeader>
                    <SheetTitle className="flex flex-col items-start mb-8">
                      <Link to="/dashboard" className="mb-2">
                        <ConFuzzledPurpleLogo style={{ width: 120 }} />
                      </Link>
                      <p>Internal Staff Hub</p>
                    </SheetTitle>
                  </SheetHeader>
                  <nav>
                    {navOptions.filter((navOption) => navOption.predicate(auth)).map((navOption, navIndex) => {
                      const IconComp = navOption.icon;
                      return <div key={navIndex} className="border-b pb-2 border-opacity-25 border-black">
                        <div className="font-bold mb-4 flex gap-2 items-center"><IconComp size={18} /> {navOption.name}</div>
                        <div className="options">
                          {navOption.options
                            .filter((option) => !option.predicate || option.predicate(auth))
                            .map((option, optionIndex) => <SheetClose asChild key={optionIndex}>
                                <Link className="block mb-2" to={option.to} title={option.title}>
                                  <span className="block">{option.title}</span>
                                  <span className="block opacity-60">{option.desc}</span>
                              </Link>
                            </SheetClose>)}
                        </div>
                      </div>
                    })}
                  </nav>

                  <Profile auth={auth} className="mt-6 flex justify-between items-center" />
                </div>
              </SheetContent>
            </Sheet>
          </div>
          <Profile auth={auth} className={"hidden md:flex items-center gap-6"} isMain={true} />
        </header>
      </Container>
    </div>
  );
}

function Profile({auth, className, isMain}: {auth: AuthContextProps, className?: string, isMain?: boolean}) {
  return <div className={clsx("profile", className)}>
    <div className={clsx("name", {'text-white': isMain})}>
      {auth.isLoading && <div>Loading</div>}
      {auth.isAuthenticated && <div>{auth.user?.profile.name}</div>}
    </div>
    {auth.isAuthenticated && (
      <Button variant="outline" onClick={() => auth.signoutRedirect()}>
        <LogOutIcon size={16} className="mr-2" /> Logout
      </Button>
    )}
  </div>;
}

type ListItemProps = {
  to: string;
  title: string;
  children: ReactNode | ReactNode[];
  className?: string;
}

const ListItem = ({ to, title, children, className }: ListItemProps) => {
  return <li>
    <NavigationMenuLink asChild>
      <Link
        to={to}
        className={cn(
          "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
          className
        )}
      >
        <div className="text-sm font-medium leading-none">{title}</div>
        <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">{children}</p>
      </Link>
    </NavigationMenuLink>
  </li>
}
