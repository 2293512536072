import { Header } from "@/components/Layout/Header.tsx";
import Container from "@/components/Container.tsx";
import { Outlet } from "react-router";
import Footer from "@/components/Footer.tsx";

export function Layout() {
  return <div>
    <Header />
    <Container className="min-h-svh pt-4">
      <Outlet />
    </Container>
    <Footer />
  </div>
}
