import { ReactNode } from "react";

interface ContainerProps {
  className?: string;
  children: ReactNode;
}

export const containerClasses = "container mx-auto";

export default function Container({ className, children }: ContainerProps) {
  return <div className={`${containerClasses} ${className ? className : ""}`}>{children}</div>;
}
