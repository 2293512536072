import { AuthContextProps } from "react-oidc-context";

export function authUserHasRoles(auth: AuthContextProps, roles: string[]) {
  if (!auth || !auth.isAuthenticated || auth.isLoading) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (auth.user?.profile as any).realm_access?.roles.some((r: string) => roles.includes(r));
}
