import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import Background from '../assets/cfz-background.png';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import CFLogo from '../assets/cfzlogo_horizontal_purple.svg?react';

export default function Home() {
  const nav = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth.isLoading) return;
    if (auth.isAuthenticated) {
      nav('/dashboard')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isLoading])

  return (
    <>
      <div className="w-full h-full flex justify-center items-center" style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}>
        <div className="bg-white p-8 text-center rounded-xl min-w-96 shadow-xl">
          <CFLogo className='mb-8' />

          <p className='font-bold text-xl mb-4'>Internal Staff Hub</p>

          <div className="bg-brok-purple-900 hover:bg-brok-purple-700 font-bold text-white px-6 py-4 rounded cursor-pointer transition-colors duration-200" onClick={() => auth.signinRedirect()}>
            Login
          </div>
        </div>
      </div>
    </>
  );
}
