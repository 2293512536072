import { useAuth } from "react-oidc-context";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { authUserHasRoles } from "@/utils.ts";

type Props = {
  requiredRoles?: string[];
  children: ReactNode | ReactNode[];
}

export function ProtectedRoute({requiredRoles, children}: Props) {
  const nav = useNavigate();
  const auth = useAuth();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (auth.isLoading) return;
    if (!auth.isAuthenticated) {
      nav("/")
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (requiredRoles && !authUserHasRoles(auth, requiredRoles)) {
      setError("You do not have the required roles to access this page.");
      return;
    }

    setLoading(false);
  }, [auth.isLoading, auth.isAuthenticated]);

  return <>
    {(loading && !error) && <div>Loading...</div>}
    {error && <div>{error}</div>}
    {(!loading && !error) && <>{children}</>}
  </>
}
