import clsx from "clsx";
import React, { ReactNode, useMemo } from "react";
import { XIcon } from "lucide-react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode | ReactNode[];
  width?: "small" | "medium" | "large";
}

export function Modal({isOpen, onClose, title, children, width}: Props) {
  function handleClose(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }

  const widthClass = useMemo(() => {
    if (width === "small") return "max-w-lg";
    if (width === "medium") return "max-w-2xl";
    if (width === "large") return "max-w-6xl";

    return "max-w-lg";
  }, [width]);

  return <div className={clsx(
    "fixed top-0 left-0 w-full h-full backdrop-blur bg-black bg-opacity-50 pt-10 pb-10 overflow-y-auto duration-200 transition-all visible z-30",
    !isOpen && "invisible pointer-events-none opacity-0",
  )} onMouseUp={e => handleClose(e)}>
    <div className={clsx(
      "flex flex-col p-8 shadow-lg rounded bg-white text-black mx-auto gap-4 duration-200 transition-all",
      !isOpen && "scale-50 translate-y-10 transform",
      widthClass
    )}>
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-bold">{title}</h1>
        <XIcon className="h-6 w-6 cursor-pointer" onClick={onClose} />
      </div>

      {children}
    </div>
  </div>
}
