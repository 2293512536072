import { Button } from "@/shadcn/components/ui/button.tsx";
import { useEffect, useMemo, useState } from "react";
import { WeaponForm, WeaponItem } from "@/components/Forms/WeaponForm.tsx";
import { Input } from "@/shadcn/components/ui/input.tsx";
import {
  BanIcon,
  CheckIcon, HashIcon,
  PencilIcon,
  PlusIcon
} from "lucide-react";
import { useAuth } from "react-oidc-context";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/shadcn/components/ui/tooltip.tsx";
import NotFoundImage from "@/assets/cfz-notfound.png";

export function Weapons() {
  const auth = useAuth();
  const [formOpen, setFormOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [items, setItems] = useState<(WeaponItem & {id: number})[]>([]);

  const [openedData, setOpenedData] = useState<WeaponItem & {id: number} | undefined>(undefined);

  useEffect(() => {
    setError(null);
    setLoading(true);

    // ?search
    fetch(`${import.meta.env.VITE_APP_API_URL}/v1/staff/items/weapons`, {
      headers: {
        Authorization: `Bearer ${auth.user?.access_token}`,
        "Content-Type": "application/json",
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (!Array.isArray(data)) {
          throw new Error("Invalid response");
        }

        setItems(data);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function updateItem(changedItem: WeaponItem & {id: number}) {
    setItems(items.map((item) => item.id === changedItem.id ? changedItem : item));
  }

  function addItem(newItem: (WeaponItem & {id: number})) {
    setItems([...items, newItem]);
  }

  const shownItems = useMemo(() => {
    if (!search) return items;

    const searchLower = search.toLowerCase();
    return items.filter((item) =>
      (
        item.id.toString().includes(searchLower) ||
        item.name.toLowerCase().includes(searchLower) ||
        item.description.toLowerCase().includes(searchLower) ||
        item.owner.toLowerCase().includes(searchLower))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, search]);

  const sortedShownItems = useMemo(() => {
    return shownItems.sort((a, b) => {
      return b.id - a.id
    });
  }, [shownItems]);

  return <div>
    <h1 className="font-bold text-lg mb-4">Weapons</h1>
    <div className="flex justify-between gap-4 mb-8">
      <div className="main flex flex-1 gap-2">
        <Input type="search" placeholder="Search..." className="text-black w-auto md:w-2/3" value={search}
               onChange={(e) => setSearch(e.target.value)} />
      </div>

      <Button onClick={() => setFormOpen(true)}>
        <PlusIcon size={20} className="mr-0 sm:mr-2" />
        <span className="hidden sm:block">Add Weapon</span>
      </Button>
    </div>

    {loading && <div>Loading...</div>}
    {error && <div>Error: {error}</div>}

    {(!loading && !error) && <div className="items grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 items-start">
      {sortedShownItems.map((item, index) => <div key={index} className="item rounded mb-4 shadow">
        <div className="relative">
          <TooltipProvider>
            {item.picture && <img className="rounded-t aspect-square" src={item.picture} />}
            {!item.picture && <img className="rounded-t aspect-square" src={NotFoundImage} />}
            <Button size="sm" className="absolute top-2 right-2" variant="outline" onClick={() => {
              setOpenedData(item)
              setFormOpen(true);
            }}><PencilIcon size={16} /></Button>

            <div className="buttons gap-2 flex absolute bottom-4 left-4">
              {item.allowed && <Tooltip>
                <TooltipTrigger><CheckIcon size="20" className="text-white" /></TooltipTrigger>
                <TooltipContent>
                  <p>Allowed in</p>
                </TooltipContent>
              </Tooltip>}
              {item.restricted && <Tooltip>
                <TooltipTrigger><BanIcon size="20" className="text-white" /></TooltipTrigger>
                <TooltipContent>
                  <p>Restricted</p>
                </TooltipContent>
              </Tooltip>}
            </div>
          </TooltipProvider>
        </div>

        <div className="p-4">
          <div className="font-bold mb-1">{item.name}</div>
          <div className="flex items-center gap-2"><HashIcon size={16} /> {item.id}</div>
        </div>
      </div>)}
    </div>}

    <WeaponForm open={formOpen} originalData={openedData} editing={!!openedData} onClose={() => {
      setFormOpen(false)
      setOpenedData(undefined)
    }} onUpdated={(item) => updateItem(item)}
                onCreated={addItem} />
  </div>
}
