import { useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/shadcn/components/ui/form";
import { Input } from "@/shadcn/components/ui/input";
import { Button } from "@/shadcn/components/ui/button";
import { Textarea } from "@/shadcn/components/ui/textarea";
import { Popover, PopoverContent, PopoverTrigger } from "@/shadcn/components/ui/popover";
import { Calendar } from "@/shadcn/components/ui/calendar";
import { cn } from "@/shadcn/utils";
import { CalendarIcon } from "lucide-react";
import dayjs from "dayjs";
import { Switch } from "@/shadcn/components/ui/switch";
import { useToast } from "@/shadcn/components/ui/use-toast";
import { Modal } from "@/components/Modal.tsx";

const schema = z.object({
  name: z.string().min(3),
  description: z.string().min(10),
  notes: z.string().optional(),
  location: z.string().min(3),
  date: z.date(),
  picture: z.string(),
  lost: z.boolean(),
  discarded: z.boolean(),
  collected: z.boolean(),
  owner: z.string(),
  collector: z.string().optional(),
  loggedBy: z.string().min(1),
})

export type FormDataItem = z.infer<typeof schema>;
export type DataItem = FormDataItem & { id: number };

type Props = {
  isOpen: boolean,
  original?: DataItem,
  onClose: () => void,
  onCreated?: (item: DataItem) => void,
  onUpdated?: (item: DataItem) => void,
}

export const ItemForm = ({ isOpen, original, onClose, onCreated, onUpdated }: Props) => {
  const { toast } = useToast()
  const auth = useAuth();

  const [originalId, setOriginalId] = useState<number | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const form = useForm<FormDataItem>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      notes: "",
      location: "",
      date: new Date(),
      description: "",
      owner: "",
      discarded: false,
      collector: "",
      picture: "", // Base64 from upload
      lost: false,
      collected: false,
      loggedBy: "",
    },
  })

  useEffect(() => {
    if (original) {
      Object.keys(original).forEach((key) => {
        if (key === "picture") {
          return;
        }

        if (key === "date") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          form.setValue(key as any, dayjs(original[key as keyof DataItem] as string).toDate());
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        form.setValue(key as any, original[key as keyof DataItem]);
      });

      setOriginalId(original.id);
    } else {
      form.reset();
      setOriginalId(null);
      setFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original])

  useEffect(() => {
    if (!isOpen) {
      form.reset();
      setOriginalId(null);
      setFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])


  const previewImage = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return null;
  }, [file])

  async function createOrEdit(values: z.infer<typeof schema>) {
    const isEditing = !!originalId;

    // Convert the image to base64
    if (file) {
      const imageBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        }
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      values.picture = imageBase64 as string;
    } else if (isEditing && original?.picture) {
      values.picture = original.picture;
    }

    fetch(`${import.meta.env.VITE_APP_API_URL}/v1/staff/items/lost${isEditing ? "/" + originalId : ""}`, {
      method: isEditing ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${auth.user?.access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        if (data?.error) {
          throw new Error(data.error);
        }

        if (onCreated && !isEditing) {
          onCreated(data);
        }

        if (onUpdated && isEditing) {
          onUpdated(data);
        }

        onClose();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        toast({
          title: "Error",
          description: err.message,
          variant: "destructive"
        })
      });
  }

  const isEditing = !!originalId;

  return <Modal isOpen={isOpen} onClose={() => onClose()} title={`${isEditing ? "Edit" : "Add"} lost item`} width={"large"}>
    <Form {...form}>
      <form onSubmit={form.handleSubmit(createOrEdit, (e) => console.log(e))}>
        <div className="lg:flex gap-8 mb-4">
          <div className="space-y-3 flex-1">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Item Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Tell us about the item"
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Notes</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Notes"
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Location</FormLabel>
                  <FormControl>
                    <Input placeholder="Hallway" {...field} />
                  </FormControl>
                  <FormDescription>
                    The location the item was found in
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Date Found</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-[240px] pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          {field.value ? (
                            dayjs(field.value).format("DD/MM/YYYY")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="picture"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Picture of Item</FormLabel>
                  <FormControl>
                    <Input id="picture" type="file" {...field} onChange={(e) => {
                      if (e.target.files) {
                        setFile(e.target.files[0]);
                      }

                      field.onChange(e);
                    }} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="space-y-3 flex-1">
            {previewImage && <div>
              <p>Preview</p>
              <img src={previewImage} alt="Preview" width={200} />
            </div>}

            {!previewImage && isEditing && original?.picture && <div>
              <p>Preview</p>
              <img src={original?.picture} alt="Preview" width={200} />
            </div>}

            <FormField
              control={form.control}
              name="lost"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
                  <div className="space-y-0.5">
                    <FormLabel>
                      Found
                    </FormLabel>
                    <FormDescription>
                      If the item has been found
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="discarded"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
                  <div className="space-y-0.5">
                    <FormLabel>
                      Discarded
                    </FormLabel>
                    <FormDescription>
                      If the item has been discarded
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="collected"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
                  <div className="space-y-0.5">
                    <FormLabel>
                      Collected
                    </FormLabel>
                    <FormDescription>
                      If the item has been collected
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            {form.getValues("collected") && <FormField
              control={form.control}
              name="collector"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Collectors Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Jake" {...field} />
                  </FormControl>
                  <FormDescription>
                    The name of the person who collected the item
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />}

            <FormField
              control={form.control}
              name="loggedBy"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Logged by</FormLabel>
                  <FormControl>
                    <Input placeholder="Jake" {...field} />
                  </FormControl>
                  <FormDescription>
                    The name of the person who logged the item
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Button type="submit">{isEditing ? "Update" : "Create"}</Button>
      </form>
    </Form>
  </Modal>
}
