import React from "react";
import ReactDOM from "react-dom/client";
import Home from "./routes/Home.tsx";
import "./index.scss";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { LostAndFound } from "./routes/ConOps/LostAndFound.tsx";
import { Login } from "./routes/Login.tsx";
import { Toaster } from "./shadcn/components/ui/toaster.tsx";
import { ProtectedRoute } from "@/routes/ProtectedRoute.tsx";
import { Layout } from "@/components/Layout/Layout.tsx";
import { Outlet } from "react-router";
import { Dashboard } from "@/routes/Dashboard.tsx";
import { Weapons } from "@/routes/ConOps/Weapons.tsx";
import { Constants } from "@/constants.ts";

const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_APP_OIDC_AUTHORITY ?? "",
  client_id: import.meta.env.VITE_APP_OIDC_CLIENT_ID ?? "",
  redirect_uri: import.meta.env.VITE_APP_OIDC_REDIRECT_URI ?? "",
  loadUserInfo: true,
  scope: import.meta.env.VITE_APP_OIDC_SCOPE ?? "",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/dashboard",
        element: <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>,
      },
      {
        path: "/conops",
        element: <ProtectedRoute requiredRoles={[Constants.roles.conops]}>
          <Outlet />
        </ProtectedRoute>,
        children: [
          {
            path: "lost-found",
            element: <LostAndFound />,
          },
          {
            path: "weapons",
            element: <Weapons />
          }
        ]
      },
    ]
  }
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <RouterProvider router={router} />
      <Toaster />
    </AuthProvider>
  </React.StrictMode>,
);
